import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import { slugify } from 'utils/strings';

type Input = {
  setAppliedFilter: (value: React.SetStateAction<string[]>) => void;
  targetQuery: ParsedUrlQuery[string];
  isReady: boolean;
};

/**
 * Apply filter values via url params.
 */
const useApplyFilterFromUrl = ({
  setAppliedFilter,
  isReady,
  targetQuery,
}: Input) => {
  useEffect(() => {
    if (!isReady) return;
    const filterStr = Array.isArray(targetQuery) ? targetQuery[1] : null;
    if (filterStr) {
      setAppliedFilter(filterStr.split('+').map((tag: string) => slugify(tag)));
    } else {
      setAppliedFilter([]);
    }
  }, [isReady, setAppliedFilter, targetQuery]);
};

export default useApplyFilterFromUrl;
