import { useRouter } from 'next/router';
import { useCallback } from 'react';

type Input = {
  /** Base url for updating path */
  baseUri: string;
  /** Router query key name to be excluded from update  */
  excludeKey?: string;
};

/**
 * Request url update by filter name
 */
const useRequestUrlUpdate = ({ baseUri, excludeKey = '' }: Input) => {
  const { replace, query } = useRouter();

  const requestURLUpdate = useCallback(
    (filters: string[]) => {
      if (!baseUri) return;
      const filtersPathPart = filters.length > 0 ? filters.join('+') : '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [excludeKey]: _, ...others } = query;
      replace(
        {
          pathname: `${baseUri}/${filtersPathPart}`,
          query: others,
        },
        undefined,
        { shallow: true }
      );
    },
    [baseUri, excludeKey, query, replace]
  );

  return {
    requestURLUpdate,
  };
};

export default useRequestUrlUpdate;
