import { SegmentationIds } from 'constants/segmentation';
import { SegmentationStore } from 'contexts/segmentation';
import { FilterConfig } from 'interfaces/collection-filter';
import { CollectionProduct } from 'models/collection/collection';
import React from 'react';
import { slugify } from 'utils/strings';

type CollecitonType = {
  products: CollectionProduct[];
  title: string;
};

const checkIfProductCorresponds = (
  productTags: string[],
  filterGroup: string,
  assignedFilter: { [key: string]: string[] },
  config: FilterConfig
): boolean => {
  // if this filter group has no active filters, then it's okay to show
  if (!assignedFilter[filterGroup].length) return true;

  // AND Filter
  if (config[filterGroup].compare === 'AND') {
    return assignedFilter[filterGroup].every((filter) =>
      productTags.includes(filter)
    );
  }

  // OR filter
  return assignedFilter[filterGroup].some((filter) =>
    productTags.includes(filter)
  );
};

export const useVisibleProductList = ({
  collection,
  activeFilter,
  activeAllergiesFilter,
  filterConfig,
  segmentation,
  shouldOmitPersonalization,
}: {
  collection?: CollecitonType | null;
  activeFilter: string[];
  activeAllergiesFilter?: string[];
  filterConfig?: FilterConfig;
  segmentation?: SegmentationIds | SegmentationStore;
  shouldOmitPersonalization?: boolean;
}): CollectionProduct[] => {
  // we need to slugify the tags
  const tagsMap = React.useMemo(() => {
    const map = {};
    if (!collection) {
      return map;
    }
    collection.products.forEach((product) => {
      map[product.id] = product.tags.map((tag) => slugify(tag));
    });
    return map;
  }, [collection]);

  const visibleProducts = React.useMemo(() => {
    if (!collection || !collection.products) {
      return [];
    }

    if (!filterConfig) {
      return [...collection.products];
    }

    // creates an object with each filter group as key and the selected filters in this group as array
    const assignedFilter = {} as { [key: string]: string[] };
    Object.entries(filterConfig).map((filterGroup) => {
      return (assignedFilter[filterGroup[0]] = filterGroup[1].tags.filter(
        (tag) => activeFilter.includes(tag)
      ));
    });

    // If the personalisation is enabled, we should sort the products by best seller,
    // in order to rank them on the top of the list.
    const sortedBestSellerProducts = shouldOmitPersonalization
      ? collection.products
      : collection.products.sort((a, b) => {
          if (!a.availableForSale || !b.availableForSale) {
            return 0;
          }

          const isBestSellerA = a.getIsBestSeller({ segment: segmentation });
          const isBestSellerB = b.getIsBestSeller({ segment: segmentation });

          if (isBestSellerA && !isBestSellerB) return -1;
          if (!isBestSellerA && isBestSellerB) return 1;
          return 0;
        });

    const products = sortedBestSellerProducts.filter((product) => {
      // Check if the product contains any tag from activeAllergiesFilter
      const hasAllergiesFilterTag = activeAllergiesFilter?.some((tag) =>
        tagsMap[product.id].includes(slugify(tag))
      );

      if (hasAllergiesFilterTag) return false;

      // loop over filter groups and check the filter
      const matchFilter = Object.keys(filterConfig).map((filterGroup) =>
        checkIfProductCorresponds(
          tagsMap[product.id],
          filterGroup,
          assignedFilter,
          filterConfig
        )
      );

      return matchFilter.every((checkedFilter) => checkedFilter === true);
    });
    return products;
  }, [
    activeAllergiesFilter,
    activeFilter,
    collection,
    filterConfig,
    segmentation,
    shouldOmitPersonalization,
    tagsMap,
  ]);

  return visibleProducts;
};
