import { BasicSegmentationInput } from 'api/product/types';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { ProductType } from 'interfaces/product';

export interface SmartCollectionItem {
  /** availability  */
  available: boolean;
  /** Tags */
  tags: string[];
  /** SKU, which is identical through out any shops and systems */
  sku: string;
  /**
   * Price of cheapest variant
   * ,which is considered with subscription discount.
   * format is {euro}.{cents} e.g 29.99
   * */
  price: string;
  /** Length of variants. Used for display from price  */
  variant_length: number;
  /** Cheapest variant id  */
  variant_id: string;
  /** Compared at price. Mostly used for bundle products  */
  compare_price: string | null;
  /** Weight in grams, e.g 2400 */
  net_grams: string;
  /** Id for product */
  product_id: string;
  /** Slug to identify a product. e.g snack-soft-bites-move-groove-fur-hunde */
  slug: string;
  /** subtitle which is generated from user's input */
  subtitle: string;
  /** Image alt for chosen variant */
  image_alt: string | null;
  /** Image src for chosen variant */
  image_src: string;
  /** True if the product is bestseller for user's attributes */
  bestseller: boolean;
  /** Product category  */
  product_category: ProductType;
  /** Product title. Title is fulfilled on BE  */
  product_title: string;
  /** Variant title e.g 3x300g, 400g / 6er Pack */
  variant_title: string;
  /** Array of variant titles  */
  variant_titles: Array<string>;
  /** Ribbon text on CDP, such as 38% sparen */
  ribbon?: { text: string; color: string };
  /**
   * Only for debugging purpose.
   * It represents the rank / order in sorting logics.
   * -1 means that it's was chosen from static collection.  */
  rank: number;
}

export interface SmartCollectionResponse {
  /** Title based on user's input e.g Bestseller für Senioren mit wählerischem Essverhalten */
  title: string;
  /** Title based on user's input used in endless cdp e.g Mehr Auswahl für Senioren mit wählerischem Essverhalten */
  endless_title: string;
  items: SmartCollectionItem[];
  /** Slug to identify specific input e.g digestion#pickyeater#senior#c */
  slug: string;
}

const CHECK_KEY: keyof SmartCollectionResponse = 'slug';

/**
 * Assert function for SmartCollectionResponse
 */
export function isSmartCollectionResponse(
  input: any
): input is SmartCollectionResponse {
  return typeof input === 'object' && input !== null && CHECK_KEY in input;
}

interface SmartCollectionInputWithSlug extends BasicSegmentationInput {
  /** Slug to identify a collection, when it's specified, static collection is returned e.g fur-hunde-nassfutter */
  slug: string;
}

interface SmartCollectionInputWithSlugAndFoodTypes
  extends SmartCollectionInputWithSlug {
  food_types: InputProductType[];
}

/** Assert function for SmartCollectionInputWithSlug */
export function isSmartCollectionInputWithSlug(
  input: SmartCollectionInput
): input is SmartCollectionInputWithSlug {
  return (input as SmartCollectionInputWithSlug).slug !== undefined;
}

/** Type guard function to check if input has only 'food_types' */
export const isOnlyFoodTypesInput = (
  input: SmartCollectionInput
): input is SmartCollectionInputWithSlugAndFoodTypes => {
  return (
    typeof input === 'object' &&
    input !== null &&
    Object.keys(input).length === 1 &&
    'food_types' in input
  );
};

export type SmartCollectionInput =
  | SmartCollectionInputWithSlug
  | BasicSegmentationInput;
